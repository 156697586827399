import React from "react";
import { graphql, PageProps } from "gatsby";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import moment from "moment";
import 'moment/locale/fr'

import { ArticleT } from "@faq/types";

import Layout from "@faq/components/Layout";

import * as s from "./Article.module.css";
import { toArticle, toCategory } from "@faq/utils/routes";

type ArticlePageProps = {
  strapiArticle: ArticleT;
  file: {
    publicURL: string;
  };
};

const Article: React.FC<PageProps<ArticlePageProps>> = ({
  data: { strapiArticle },
}) => {
  const { title, content, category, slug, updatedAt } = strapiArticle;
  const date = moment(updatedAt).locale('fr').format('DD MMMM YYYY à HH:mm')

  const description = content.slice(0, 50);

  return (
    <Layout
      seo={{ pageTitle: title, pageDescription: description, pageUrl: toArticle(category.slug, slug) }}
      breadcrumb={[
        { to: toCategory(category.slug), label: category.title },
        { label: title },
      ]}
    >
      <div className={s.wrapper}>

        <h1 className={s.title}>{title}</h1>
        <div className={s.date}>
          Modifié le : {date}
        </div>
        <ReactMarkdown
          children={content}
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          linkTarget="_blank"
          className={s.content}
          transformImageUri={uri => uri.startsWith("http") ? uri : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`} />
      </div>
    </Layout>
  );
};

export default Article;

export const query = graphql`
  query ArticleQuery($id: Int!) {
    strapiArticle(strapiId: { eq: $id }) {
      title
      content
      slug
      updatedAt
      category {
        title
        slug
      }
    }
  }
`;
